<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>银行卡信息管理</span>
      </div>
    </div>
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :actions-type="actionsType"
      :is-full="false"
      :hide-row-selection="true"
      :hide-pagination="true"
      @emitRefreshData="refreshGoFirstPage"
    >
      <!-- 按钮 -->
      <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="8">
          <a-input
            v-model="filterText"
            :placeholder="l('SearchWithThreeDot')"
          />
        </a-col>
        <a-col class="gutter-row" :span="6">
          <a-button type="primary" @click="getData">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="10" align="right">
          <a-button type="primary" @click="createOrEdit(null)">
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
          <!-- <a-button
            type="default"
            v-if="isGranted('Pages.Company.Edit')"
            @click="createOrEdit(2)"
          >
            <a-icon type="edit" />
            {{ l("Edit") }}
          </a-button> -->
          <!-- <a-button
            type="danger"
            @click="deleteBankInfo()"
          >
            <a-icon type="delete" />
            删除
          </a-button> -->
        </a-col>
      </a-row>
    </ele-table>

    <!-- <div class="my-md">
        <a-alert :type="'info'" :showIcon="true" style="margin: 10px 0;">
          <template slot="message">
            <span v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"></span>
            <a @click="restCheckStatus()" class="ml-md">{{ l("ClearEmpty") }}</a>
            <a-divider type="vertical"></a-divider>
            <a @click="refreshGoFirstPage()">{{ l("Refresh") }}</a>
          </template>
        </a-alert>
      </div> -->

    <a-table
      v-if="false"
      class="list-table"
      @change="sortData"
      :pagination="false"
      :row-selection="null"
      :rowKey="(tableDatas) => tableDatas.id"
      :columns="columns"
      :data-source="tableData"
      selectedRowKeys
    >
      <span slot="isDefault" slot-scope="isDefault">
        <a-tag :color="isDefault == '是' ? 'geekblue' : ''">
          {{ isDefault.toUpperCase() }}
        </a-tag>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a
          class="table-edit"
          @click="setDefault(record)"
          v-if="record.isDefault == '否'"
        >
          <a-icon type="setting" /> 设为默认
        </a>
        <a class="table-edit" @click="createOrEdit(record)">
          <a-icon type="edit" /> 修改
        </a>
        <!-- <a-popconfirm
            placement="top"
            class="table-delete"
            :okText="l('Ok')"
            :cancelText="l('Cancel')"
            @confirm="deleteBankInfo(record.id)"
          >
            <template slot="title">
              {{ l("ConfirmDeleteWarningMessage") }}
            </template>
            <a class="table-delete">
              <a-icon type="delete" />删除
            </a>
          </a-popconfirm> -->
      </span>
    </a-table>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import CreateOrEditBankInfo from "../create-or-edit-bankInfo/create-or-edit-bankInfo";
import { PersonnelServiceProxy } from "../services/personnel-proxies";
import EleTable from "@/components/ele-table";

export default {
  mixins: [ModalComponentBase],
  components: { EleTable },
  name: "bankInfoList",
  data() {
    return {
      spinning: false,
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      _serviceProxy: "",
      commonService: null,
      actionsType: {},
      columns: [
        {
          title: "银行卡号",
          dataIndex: "bankCardNo",
          align: "center",
          scopedSlots: { customRender: "bankCardNo" },
        },
        {
          title: "银行名称",
          dataIndex: "bankName",
          align: "center",
        },

        // {
        //   title: "所属分行",
        //   dataIndex: "bankBranchName",
        //   align: "center",
        // },
        {
          title: "开户地",
          dataIndex: "bankArea",
          align: "center",
        },
        {
          title: "是否默认",
          dataIndex: "isDefault",
          align: "center",
          scopedSlots: { customRender: "isDefault" },
          type: "tag",
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  created() {
    this.fullData();
    this._serviceProxy = new PersonnelServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType();
  },
  methods: {
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("设为默认"),
              icon: "setting",
              showFunc(record) {
                return record.isDefault == "否";
              },
              fn: (data) => {
                _this.setDefault(data);
              },
            },
            {
              granted: true,
              name: this.l("修改"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data);
              },
            },
          ],
          // delete: { granted: this.isGranted('Pages.Customer.Delete'), name: this.l("Delete"), fn: (data) => {
          //   _this.deleteItem(data.id)
          // } },
        },
      };
      this.actionsType = obj;
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData(this.reqUrl.getPaged);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData(this.reqUrl.getPaged);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      let id = this._props.modelData.id;
      var response = await this._serviceProxy.getPagedForBanInfo(
        id,
        this.filterText
      );
      this.spinning = false;
      response.map((item) => {
        item.isDefault = item.isDefault ? "是" : "否";
        item.isDefaultColor = item.isDefault ? "geekblue" : "";
      });
      console.log(response);
      this.tableData = response;
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },

    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getData();
    },
    /**
     * 新建修改
     */
    createOrEdit(item) {
      //   console.log(this.selectedRows);
      //   if (type === 2) {
      //     const selectCount = this.selectedRows.length;
      //     if (selectCount != 1) {
      //       abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "编辑"));
      //       return;
      //     }
      //   }
      //   let id = type == 2 ? this.selectedRows[0].id : "";
      let personnelId = this._props.modelData.id;
      ModalHelper.create(
        CreateOrEditBankInfo,
        {
          id: item ? item.id : "",
          personnelId: personnelId,
          isRealNamed: this.isRealNamed,
        },
        {
          width: "600px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    async deleteBankInfo(id) {
      // 改为单条删除
      this.spinning = true;
      try {
        const res = await this._serviceProxy.deleteBankInfo(id);
        this.refreshGoFirstPage();
        this.$notification["success"]({
          message: this.l("SuccessfullyDeleted"),
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
      // const selectCount = this.selectedRowKeys.length;
      // if (selectCount <= 0) {
      //   abp.message.warn(this.l("PleaseSelectAXPlaceHolder", "删除"));
      //   return;
      // }
      // this.message.confirm("是否确定删除", (res) => {
      //   if (res) {
      //     let id = this.selectedRows[0].id;
      //     this.spinning = true;
      //     this._serviceProxy
      //       .deleteBankInfo(id)
      //       .finally(() => {
      //         this.spinning = false;
      //       })
      //       .then(() => {
      //         this.refreshGoFirstPage();
      //         this.$notification["success"]({
      //           message: this.l("SuccessfullyDeleted"),
      //         });
      //       });
      //   }
      // });
    },
    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    sortData() {},
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    setDefault(item) {
      this.message.confirm("是否设为默认?", "", (res) => {
        if (res) {
          let id = item.id;
          this.spinning = true;
          this._serviceProxy
            .setBankDefault(id)
            .finally(() => {
              this.spinning = false;
            })
            .then(() => {
              this.refreshGoFirstPage();
              this.$notification["success"]({
                message: "更改成功",
              });
            });
        }
      });
    },
  },
  computed: {
    // rowSelection() {
    //   return {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //       this.selectedRowKeys = selectedRowKeys;
    //       this.selectedRows = selectedRows;
    //       console.log(
    //         `selectedRowKeys: ${selectedRowKeys}`,
    //         "selectedRows: ",
    //         selectedRows
    //       );
    //     },
    //   };
    // },
    initRowSelection() {
      const rowSelection = {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
      return rowSelection;
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}
.list-table {
  border: 1px solid #e8e8e8;
  // margin: 20px;
  // padding: 20px;
  .table-edit {
    margin-left: 10px;
    // margin-right: 10px;
  }
}
</style>
