<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>重置实名状态</span>
      </div>
    </div>
    <div class="m-container">
      <div class="item">注意事项：</div>
      <div class="item">
        1.重置后不管当前账号是不是原始账号，都引导用户去小程序[个人中心]-[设置]-[退出登陆]然后重新登陆一下！！！避免不必要的麻烦！！
      </div>
      <div class="item">
        2.重置如果直接更换手机号，需要系统内无此手机号的有效数据（有姓名有身份证号码则有效）
      </div>
      <div class="item">
        3.重置后如果实名类型是4要素，新手机号必须满足4要素的要求才可能重新实名通过
      </div>
      <div class="item">
        4.重置后会保留之前签署的数据和基本信息，但是会清理实名数据，所以需要重新实名!
      </div>

      <a-input
        v-model="item.newPhoneNum"
        style="margin: 10px 0"
        placeholder="请输入需要重置的新手机号码"
      />
      <div class="btn-w">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit" @click="handleSubmit">
          {{ l("Save") }}
        </a-button>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { message } from "ant-design-vue";

export default {
  mixins: [ModalComponentBase],
  name: "clearrealname",
  components: {},
  data: function () {
    return {
      spinning: false,
      _serviceProxy: "",
      item: {
        id: "",
        newPhoneNum: "",
      },
      form: {},
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    console.log(this.modelData);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },

  methods: {
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      if (!this.item.newPhoneNum) {
        abp.message.warn("请输入需要重置的新手机号！！！");
        return;
      }
      try {
        this.spinning = true;
        let res = await this.commonService.post({
          url: "/api/services/app/Personnel/ClearRealName",
          params: {
            personalId: this.modelData.id,
            newPhone: this.item.newPhoneNum,
          },
        });
        this.spinning = false;

        this.$notification["success"]({
          message: this.l("操作成功！"),
        });
        this.success(true);
      } catch {
        this.spinning = false;
      }
    },
  },
};
</script>
<style>
.m-container {
  width: 100%;
  height: 100%;
  background-color: white;
}
.item {
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  color: red;
}
.title {
  text-indent: 10px;
  background-color: rgb(24 144 255 / 20%);
  color: #333;
  padding: 5px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-weight: 600;
}
.body {
  width: 100%;
}
.item-table {
  text-align: center;
  border-color: #ccc;
  width: 100%;
}
.item-table > tr > td {
  height: 34px;
}
.tdheader {
  background-color: #eee;
  width: 16%;
}
</style>


