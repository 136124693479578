<template>
  <!-- <a-spin :spinning="spinning"> -->
    <section>
      <div class="modal-header">
        <div class="modal-title" v-if="params.type === 'edit'">
          {{ l("Edit") }}
        </div>
        <div class="modal-title" v-if="params.type === 'preview'">
          {{ l("查看") }}
        </div>
        <div class="modal-title" v-if="params.type === 'create'">
          {{ l("创建") }}
        </div>
        <div class="modal-title" v-if="params.type === 'others'">
          {{ l("查看") }}
        </div>
      </div>
      
      <div class="list-w" v-for="(oItem, oIndex) in list" :key="oIndex">
        <div class="l-t">{{oItem.title}}</div>
        <div class="l-w">
          <!-- <a-form :label-col="{ span: 9 }" :wrapper-col="{ span: 15 }" :form="form"> -->
          <a-form :label-col="{ span: 9 }" :wrapper-col="{ span: 15 }" v-if="!oItem.extendInfo">
            <a-row :gutter="12" class="form-wrapper">
              <a-col :span="item.span ? item.span : 12" v-for="(item, index) in oItem.list" :key="index">
                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'commonInput'">
                  <a-input
                    :disabled="item.disabled"
                    :placeholder="l('请输入')"
                    v-model="item.value"
                  />
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'numberInput'">
                  <a-input-number 
                    :disabled="item.disabled" :min="item.min" :max="item.max" @change="item.changeFn" 
                    v-model="item.value"
                  />
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'switch'">
                  <a-switch v-decorator="[`${item.key}`, { valuePropName: 'checked' }]" />
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'datePicker'">
                  <a-date-picker 
                    :disabled="item.disabled"
                    :placeholder="'请选择日期'"
                    :default-value="item.value?moment(item.value, 'YYYY-MM-DD') : null"
                  />
                </a-form-item>


                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'rangeDatePicker'">
                  <a-range-picker
                    :disabled="item.disabled"
                    :placeholder="['开始日期', '结束日期']"
                    valueFormat="YYYY-MM-DD"
                    :default-value="[moment(item.value, 'YYYY-MM-DD'), moment(item.value, 'YYYY-MM-DD')]"
                  
                  />
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'textarea'">
                  <a-input
                    type="textarea"
                    :disabled="item.disabled"
                    :placeholder="l('请输入')"
                    v-model="item.value"
                  />
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'commonSelect'">
                  <a-select
                    :default-value="item.value"
                    placeholder="请选择"
                    @change="item.changeFn"
                  >
                    <a-select-option :disabled="item.disabled" :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                      {{it.label}}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'multipleSelect'">
                  <a-select
                    v-model="item.value"
                    mode="multiple"
                    placeholder="请选择"
                    @change="item.changeFn"
                  >
                    <a-select-option :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                      {{it.label}}
                    </a-select-option>
                  </a-select>
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'radio'">
                  <a-radio-group v-model="item.value" :disabled="item.disabled" :default-value="item.value">
                    <a-radio :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                      {{it.label}}
                    </a-radio>
                  </a-radio-group>
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'checkbox'">
                  <a-checkbox-group
                    :default-value="item.value.split(',')"
                    style="width: 100%;"
                  >
                    <a-row>
                      <a-col>
                        <a-checkbox :value="it.value" v-for="(it, i) in item.defaultSelectList" :key="i">
                          {{item.label}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </a-form-item>  

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'cascader'">
                  <a-cascader :default-value="item.value" :disabled="item.disabled" :options="item.defaultSelectList" placeholder="请选择" @change="item.changeFn" />
                </a-form-item>

                <a-form-item :label="item.label" :required="item.required ? item.required : false" v-if="item.type === 'upload'">
                  <a-upload
                    :action="item.uploadUrl"
                    :multiple="true"
                    :data="{ subdir: item.subdirName }"
                    :file-list="item.value"
                    :showUploadList="{
                      showPreviewIcon: true, showRemoveIcon: true 
                    }"
                    @change="item.changeFn"
                  >
                    <a-button v-if="params.type !== 'preview' && item.value.length < item.limitedLength"> <a-icon type="upload" /> 上传附件 </a-button>
                    <span v-if="params.type === 'preview' && (!item.value || !item.value.length)">暂无</span>
                  </a-upload>
                </a-form-item>


                <a-form-item :label="item.label" :required="item.required" v-if="item.type === 'desc' && item.value">
                  <span>{{item.value}}</span>
                </a-form-item> 

              </a-col>
            </a-row>
          </a-form>


          <div class="et-info" v-if="oItem.extendInfo && oItem.extendInfo.length">
            <a  v-for="(item, index) in oItem.extendInfo" :key="index">
              <div class="ei-w" @click="openModal(oItem, index)">
                <a-tooltip placement="right">
                  <template slot="title">
                    <span>点击查看</span>
                  </template>
                  <span class="it" v-for="(it, i) in item.items" :key="i">{{it.value}}</span>
                </a-tooltip>
              </div>
            </a>
          </div>

        </div>
      </div>

      
      <a-modal
        centered
        :title="inputModalParams.title"
        :visible="inputModalParams.visible"
        :confirm-loading="inputModalParams.confirmLoading"
        :cancelText="inputModalParams.cancelText"
        :okText="inputModalParams.okText"
        :maskClosable="inputModalParams.maskClosable"
        :destroyOnClose="inputModalParams.destroyOnClose"
        :width="inputModalParams.width"
        @ok="inputModalParams.confirm"
        @cancel="inputModalParams.cancel"
      >
        <div>
          <a-form :form="inputModalParams.form" v-bind="formItemLayout" >
            <a-form-item :label="item.label" v-for="(item, i) in inputModalParams.list" :key="i">
              <a-input
               :disabled="true"
                v-model="item.value"
              />
            </a-form-item>
          </a-form>
        </div>
      </a-modal>

      <!-- <div class="modal-footer" v-if="params.type !== 'preview'">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ params.cancelBtnTxt ? params.cancelBtnTxt : l("Cancel") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
          <a-icon type="save" />
          {{ params.okBtnTxt ? params.okBtnTxt : l("Save") }}
        </a-button>
      </div> -->

    </section>
  <!-- </a-spin> -->
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
// import { AppConsts } from "@/abpPro/AppConsts";
// import { environment } from "@/environments/environment";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";

export default {
  name: "react-modify",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      commonService: null,
      form: this.$form.createForm(this, { name: "rm" }),
      defaultRules: [{ required: true, message: this.l('ThisFieldIsRequired') }],
      list: [],
      otherParams: {},
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 620,
        title: "",
        cancelText: "取消",
        okText: "确认",
        list: [],
        form: this.$form.createForm(this, { name: "mf" }),
        confirm: () => {
          this.inputModalParams.visible = false;
        },
        cancel: () => {
          this.inputModalParams.visible = false;
        },
      },
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },
    }
  },
  created() {
    this.fullData();
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    // console.log(this.params.data)
  },
  mounted() {
    this.$nextTick( async () => {
      this.list = this.params.data
      if (this.params.type === 'preview' || this.params.type === 'edit' || this.params.type === 'others') {
        // if (this.params.reqParams[0].key === 'id' && this.params.reqParams[0].value) {
        if (this.params.type === 'others' || (this.params.reqParams[0].key === 'id' && this.params.reqParams[0].value)) {
          // this.form.setFieldsValue({...res})
          if (this.params.reqUrl && this.params.reqUrl.getById) {
            try {
              const res = await this.commonService.get({
                url: this.params.reqUrl.getById,
                params: {id: this.params.reqParams && this.params.reqParams.length ? this.params.reqParams[0].value : null}
              })
              console.log(res)
              this.params.getItemfn(res, this.form, this.otherParams)
            } catch (error) {
              console.log(error)
              throw new Error(error)
            }
          } else {
            this.params.getItemfn(null, this.form, this.otherParams)
          }
          // else if(this.params.reqUrl.isSetFieldsValue){
          //   this.params.getItemfn(true, this.form, this.otherParams)
          // }
        }
      }
    })
  },
  methods: {
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.saving = true
          const res = await this.params.submitfn(values, this.params)
          this.saving = false
          if (res === 'other') {
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            })
          } else if (res === true) {
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            })
            this.success(res);
          } else {
            console.log('fail')
          }
        }
      })
    },
    moment,
    openModal(oItem, index) {
      let list = oItem.list[index].item

      let _this = this;
      this.inputModalParams.visible = true
      this.inputModalParams.title = oItem.title
      this.inputModalParams.list = list.map(item => {
        let value
        if (item.type === 'datePicker') {
          if (item.value.indexOf(' ') !== -1) {
            value = item.value.split(' ')[0]
          } else if (item.value.indexOf('T') !== -1)  {
            value = item.value.split('T')[0]
          }
        } else {
          value = item.value
        }
        return {
          ...item,
          value: value
        }
      })
    },
  }
}

</script>


<style lang="less" scoped>
@import "../../../../styles/common.less";

.list-w{
  .l-t{
    padding: 10px 0;
    margin-bottom: 10px;
    line-height: 20px;
    font-weight: bold;
    font-size: 16px;
    color: @color-blue;
    #borderBottom-1px(@color-border-gray);
  }
  .l-w{
    .ant-form-item{
      margin-bottom: 6px !important;
    }
  }
  .et-info{
    a{
      color: @color-black;;
    }
    .ei-w{
      margin-bottom: 10px;
      &:hover{
        color: blue;;
      }
      .it{
        margin-right: 20px;
      }
      .it:first-of-type{
        #fw;
      }
    }
  }
}

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.modal-footer{
  border: none;
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .preview-list {
    margin-bottom: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 30px;
    }
  }
}
</style>