<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="id">{{ l("Edit") }}</span>
        <span v-if="!id">{{ l("Create") }}</span>
      </div>
    </div>
    <a-form
      :form="form"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      @submit="handleSubmit"
    >
      <a-form-item label="银行卡号" has-feedback>
        <a-row :gutter="12">
          <a-col :span="19">
            <a-input
              placeholder="请输入银行卡号"
              v-decorator="[
                'bankCardNo',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-col>
          <a-col :span="5">
            <a-button
              type="primary"
              @click="validateBankCardNo()"
              :loading="loading"
            >
              <span v-if="!loading">验证</span>
              <span v-else>验证中</span>
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="银行名称" has-feedback>
        <a-input
          placeholder="请输入银行名称"
          v-decorator="[
            'bankName',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="开户地" has-feedback>
        <a-input
          placeholder="请输入开户地"
          v-decorator="[
            'bankArea',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <!-- <a-form-item label="所属分行" has-feedback>
        <a-input
          placeholder="请输入所属分行"
          v-decorator="[
            'bankBranchName',
            {
              rules: [
                {
                  required: true,
                  message: l('validation.required'),
                },
              ],
            },
          ]"
        />
      </a-form-item> -->
      <!-- <a-form-item class="btn--container">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit">
          {{ l("Save") }}
        </a-button>
      </a-form-item> -->
    </a-form>
    <div class="btn-w">
      <a-button type="button" @click="close()">
        {{ l("Cancel") }}
      </a-button>
      <a-button type="primary" html-type="submit" @click="handleSubmit">
        {{ l("Save") }}
      </a-button>
    </div>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { PersonnelServiceProxy } from "../services/personnel-proxies";
import { message } from "ant-design-vue";
import moment from "moment";
export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-bankInfo",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      _serviceProxy: "",
      labelCol: {
        span: 5,
      },
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new PersonnelServiceProxy(this.$apiUrl, this.$api);
  },
  async mounted() {
    // console.log(this.personnelId);
    // console.log(this.id);
    if (this.id) {
      await this.getData();
    }
  },
  methods: {
    validateBankCardNo() {
      const bankCardNo = this.form.getFieldValue("bankCardNo");
      console.log(bankCardNo);
      if (bankCardNo === undefined || bankCardNo === "") {
        message.info("请输入银行卡号");
        return;
      }
      this.loading = true;
      this._serviceProxy
        .getBankInfoByJd(bankCardNo)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            const data = res.data;
            this.form.setFieldsValue({
              bankName: data.bankName,
              bankArea: data.area,
            });
          } else {
            message.error(res.message);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      var res = await this._serviceProxy.getEditByIdForBankInfo(this.id);
      this.spinning = false;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          ...res,
          id: this.id,
        });
      });
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        console.log(err);
        console.log(values);
        if (!err) {
          try {
            this.spinning = true;
            let res = await this._serviceProxy.saveBankInfo({
              ...values,
              id: this.id,
              personnelId: this.personnelId,
            });
            this.spinning = false;
            console.log(res);
            if (res.status == 200) {
              this.$notification["success"]({
                message: this.l("SavedSuccessfully"),
              });
              this.success(true);
            }
          } catch {
            this.spinning = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  margin: 10px auto;
  text-align: center;
}

.btn-w {
  width: 100%;
  display: block;
  margin: 10px auto;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>