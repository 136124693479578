const regionData = [
    {
        "c": "110000",
        "n": "北京",
        "a": [
            {
                "c": "110100",
                "n": "北京市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "120000",
        "n": "天津",
        "a": [
            {
                "c": "120100",
                "n": "天津市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "130000",
        "n": "河北省",
        "a": [
            {
                "c": "130100",
                "n": "石家庄市",
                "a": [

                ]
            },
            {
                "c": "130200",
                "n": "唐山市",
                "a": [

                ]
            },
            {
                "c": "130300",
                "n": "秦皇岛市",
                "a": [

                ]
            },
            {
                "c": "130400",
                "n": "邯郸市",
                "a": [

                ]
            },
            {
                "c": "130500",
                "n": "邢台市",
                "a": [

                ]
            },
            {
                "c": "130600",
                "n": "保定市",
                "a": [

                ]
            },
            {
                "c": "130700",
                "n": "张家口市",
                "a": [

                ]
            },
            {
                "c": "130800",
                "n": "承德市",
                "a": [

                ]
            },
            {
                "c": "130900",
                "n": "沧州市",
                "a": [

                ]
            },
            {
                "c": "131000",
                "n": "廊坊市",
                "a": [

                ]
            },
            {
                "c": "131100",
                "n": "衡水市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "140000",
        "n": "山西省",
        "a": [
            {
                "c": "140100",
                "n": "太原市",
                "a": [

                ]
            },
            {
                "c": "140200",
                "n": "大同市",
                "a": [

                ]
            },
            {
                "c": "140300",
                "n": "阳泉市",
                "a": [

                ]
            },
            {
                "c": "140400",
                "n": "长治市",
                "a": [

                ]
            },
            {
                "c": "140500",
                "n": "晋城市",
                "a": [

                ]
            },
            {
                "c": "140600",
                "n": "朔州市",
                "a": [

                ]
            },
            {
                "c": "140700",
                "n": "晋中市",
                "a": [

                ]
            },
            {
                "c": "140800",
                "n": "运城市",
                "a": [

                ]
            },
            {
                "c": "140900",
                "n": "忻州市",
                "a": [

                ]
            },
            {
                "c": "141000",
                "n": "临汾市",
                "a": [

                ]
            },
            {
                "c": "141100",
                "n": "吕梁市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "210000",
        "n": "辽宁省",
        "a": [
            {
                "c": "210100",
                "n": "沈阳市",
                "a": [

                ]
            },
            {
                "c": "210200",
                "n": "大连市",
                "a": [

                ]
            },
            {
                "c": "210300",
                "n": "鞍山市",
                "a": [

                ]
            },
            {
                "c": "210400",
                "n": "抚顺市",
                "a": [

                ]
            },
            {
                "c": "210500",
                "n": "本溪市",
                "a": [

                ]
            },
            {
                "c": "210600",
                "n": "丹东市",
                "a": [

                ]
            },
            {
                "c": "210700",
                "n": "锦州市",
                "a": [

                ]
            },
            {
                "c": "210800",
                "n": "营口市",
                "a": [

                ]
            },
            {
                "c": "210900",
                "n": "阜新市",
                "a": [

                ]
            },
            {
                "c": "211000",
                "n": "辽阳市",
                "a": [

                ]
            },
            {
                "c": "211100",
                "n": "盘锦市",
                "a": [

                ]
            },
            {
                "c": "211200",
                "n": "铁岭市",
                "a": [

                ]
            },
            {
                "c": "211300",
                "n": "朝阳市",
                "a": [

                ]
            },
            {
                "c": "211400",
                "n": "葫芦岛市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "220000",
        "n": "吉林省",
        "a": [
            {
                "c": "220100",
                "n": "长春市",
                "a": [

                ]
            },
            {
                "c": "220200",
                "n": "吉林市",
                "a": [

                ]
            },
            {
                "c": "220300",
                "n": "四平市",
                "a": [

                ]
            },
            {
                "c": "220400",
                "n": "辽源市",
                "a": [

                ]
            },
            {
                "c": "220500",
                "n": "通化市",
                "a": [

                ]
            },
            {
                "c": "220600",
                "n": "白山市",
                "a": [

                ]
            },
            {
                "c": "220700",
                "n": "松原市",
                "a": [

                ]
            },
            {
                "c": "220800",
                "n": "白城市",
                "a": [

                ]
            },
            {
                "c": "222400",
                "n": "延边朝鲜族自治州",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "230000",
        "n": "黑龙江",
        "a": [
            {
                "c": "230100",
                "n": "哈尔滨市",
                "a": [

                ]
            },
            {
                "c": "230200",
                "n": "齐齐哈尔市",
                "a": [

                ]
            },
            {
                "c": "230300",
                "n": "鸡西市",
                "a": [

                ]
            },
            {
                "c": "230400",
                "n": "鹤岗市",
                "a": [

                ]
            },
            {
                "c": "230500",
                "n": "双鸭山市",
                "a": [

                ]
            },
            {
                "c": "230600",
                "n": "大庆市",
                "a": [

                ]
            },
            {
                "c": "230700",
                "n": "伊春市",
                "a": [

                ]
            },
            {
                "c": "230800",
                "n": "佳木斯市",
                "a": [

                ]
            },
            {
                "c": "230900",
                "n": "七台河市",
                "a": [

                ]
            },
            {
                "c": "231000",
                "n": "牡丹江市",
                "a": [

                ]
            },
            {
                "c": "231100",
                "n": "黑河市",
                "a": [

                ]
            },
            {
                "c": "231200",
                "n": "绥化市",
                "a": [

                ]
            },
            {
                "c": "232700",
                "n": "大兴安岭地区",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "310000",
        "n": "上海",
        "a": [
            {
                "c": "310100",
                "n": "上海市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "320000",
        "n": "江苏省",
        "a": [
            {
                "c": "320100",
                "n": "南京市",
                "a": [

                ]
            },
            {
                "c": "320200",
                "n": "无锡市",
                "a": [

                ]
            },
            {
                "c": "320300",
                "n": "徐州市",
                "a": [

                ]
            },
            {
                "c": "320400",
                "n": "常州市",
                "a": [

                ]
            },
            {
                "c": "320500",
                "n": "苏州市",
                "a": [

                ]
            },
            {
                "c": "320600",
                "n": "南通市",
                "a": [

                ]
            },
            {
                "c": "320700",
                "n": "连云港市",
                "a": [

                ]
            },
            {
                "c": "320800",
                "n": "淮安市",
                "a": [

                ]
            },
            {
                "c": "320900",
                "n": "盐城市",
                "a": [

                ]
            },
            {
                "c": "321000",
                "n": "扬州市",
                "a": [

                ]
            },
            {
                "c": "321100",
                "n": "镇江市",
                "a": [

                ]
            },
            {
                "c": "321200",
                "n": "泰州市",
                "a": [

                ]
            },
            {
                "c": "321300",
                "n": "宿迁市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "330000",
        "n": "浙江省",
        "a": [
            {
                "c": "330100",
                "n": "杭州市",
                "a": [

                ]
            },
            {
                "c": "330200",
                "n": "宁波市",
                "a": [

                ]
            },
            {
                "c": "330300",
                "n": "温州市",
                "a": [

                ]
            },
            {
                "c": "330400",
                "n": "嘉兴市",
                "a": [

                ]
            },
            {
                "c": "330500",
                "n": "湖州市",
                "a": [

                ]
            },
            {
                "c": "330600",
                "n": "绍兴市",
                "a": [

                ]
            },
            {
                "c": "330700",
                "n": "金华市",
                "a": [

                ]
            },
            {
                "c": "330800",
                "n": "衢州市",
                "a": [

                ]
            },
            {
                "c": "330900",
                "n": "舟山市",
                "a": [

                ]
            },
            {
                "c": "331000",
                "n": "台州市",
                "a": [

                ]
            },
            {
                "c": "331100",
                "n": "丽水市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "340000",
        "n": "安徽省",
        "a": [
            {
                "c": "340100",
                "n": "合肥市",
                "a": [

                ]
            },
            {
                "c": "340200",
                "n": "芜湖市",
                "a": [

                ]
            },
            {
                "c": "340300",
                "n": "蚌埠市",
                "a": [

                ]
            },
            {
                "c": "340400",
                "n": "淮南市",
                "a": [

                ]
            },
            {
                "c": "340500",
                "n": "马鞍山市",
                "a": [

                ]
            },
            {
                "c": "340600",
                "n": "淮北市",
                "a": [

                ]
            },
            {
                "c": "340700",
                "n": "铜陵市",
                "a": [

                ]
            },
            {
                "c": "340800",
                "n": "安庆市",
                "a": [

                ]
            },
            {
                "c": "341000",
                "n": "黄山市",
                "a": [

                ]
            },
            {
                "c": "341100",
                "n": "滁州市",
                "a": [

                ]
            },
            {
                "c": "341200",
                "n": "阜阳市",
                "a": [

                ]
            },
            {
                "c": "341300",
                "n": "宿州市",
                "a": [

                ]
            },
            {
                "c": "341500",
                "n": "六安市",
                "a": [

                ]
            },
            {
                "c": "341600",
                "n": "亳州市",
                "a": [

                ]
            },
            {
                "c": "341700",
                "n": "池州市",
                "a": [

                ]
            },
            {
                "c": "341800",
                "n": "宣城市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "350000",
        "n": "福建省",
        "a": [
            {
                "c": "350100",
                "n": "福州市",
                "a": [

                ]
            },
            {
                "c": "350200",
                "n": "厦门市",
                "a": [

                ]
            },
            {
                "c": "350300",
                "n": "莆田市",
                "a": [

                ]
            },
            {
                "c": "350400",
                "n": "三明市",
                "a": [

                ]
            },
            {
                "c": "350500",
                "n": "泉州市",
                "a": [

                ]
            },
            {
                "c": "350600",
                "n": "漳州市",
                "a": [

                ]
            },
            {
                "c": "350700",
                "n": "南平市",
                "a": [

                ]
            },
            {
                "c": "350800",
                "n": "龙岩市",
                "a": [

                ]
            },
            {
                "c": "350900",
                "n": "宁德市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "360000",
        "n": "江西省",
        "a": [
            {
                "c": "360100",
                "n": "南昌市",
                "a": [

                ]
            },
            {
                "c": "360200",
                "n": "景德镇市",
                "a": [

                ]
            },
            {
                "c": "360300",
                "n": "萍乡市",
                "a": [

                ]
            },
            {
                "c": "360400",
                "n": "九江市",
                "a": [

                ]
            },
            {
                "c": "360500",
                "n": "新余市",
                "a": [

                ]
            },
            {
                "c": "360600",
                "n": "鹰潭市",
                "a": [

                ]
            },
            {
                "c": "360700",
                "n": "赣州市",
                "a": [

                ]
            },
            {
                "c": "360800",
                "n": "吉安市",
                "a": [

                ]
            },
            {
                "c": "360900",
                "n": "宜春市",
                "a": [

                ]
            },
            {
                "c": "361000",
                "n": "抚州市",
                "a": [

                ]
            },
            {
                "c": "361100",
                "n": "上饶市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "370000",
        "n": "山东省",
        "a": [
            {
                "c": "370100",
                "n": "济南市",
                "a": [

                ]
            },
            {
                "c": "370200",
                "n": "青岛市",
                "a": [

                ]
            },
            {
                "c": "370300",
                "n": "淄博市",
                "a": [

                ]
            },
            {
                "c": "370400",
                "n": "枣庄市",
                "a": [

                ]
            },
            {
                "c": "370500",
                "n": "东营市",
                "a": [

                ]
            },
            {
                "c": "370600",
                "n": "烟台市",
                "a": [

                ]
            },
            {
                "c": "370700",
                "n": "潍坊市",
                "a": [

                ]
            },
            {
                "c": "370800",
                "n": "济宁市",
                "a": [

                ]
            },
            {
                "c": "370900",
                "n": "泰安市",
                "a": [

                ]
            },
            {
                "c": "371000",
                "n": "威海市",
                "a": [

                ]
            },
            {
                "c": "371100",
                "n": "日照市",
                "a": [

                ]
            },
            {
                "c": "371200",
                "n": "莱芜市",
                "a": [

                ]
            },
            {
                "c": "371300",
                "n": "临沂市",
                "a": [

                ]
            },
            {
                "c": "371400",
                "n": "德州市",
                "a": [

                ]
            },
            {
                "c": "371500",
                "n": "聊城市",
                "a": [

                ]
            },
            {
                "c": "371600",
                "n": "滨州市",
                "a": [

                ]
            },
            {
                "c": "371700",
                "n": "菏泽市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "410000",
        "n": "河南省",
        "a": [
            {
                "c": "410100",
                "n": "郑州市",
                "a": [

                ]
            },
            {
                "c": "410200",
                "n": "开封市",
                "a": [

                ]
            },
            {
                "c": "410300",
                "n": "洛阳市",
                "a": [

                ]
            },
            {
                "c": "410400",
                "n": "平顶山市",
                "a": [

                ]
            },
            {
                "c": "410500",
                "n": "安阳市",
                "a": [

                ]
            },
            {
                "c": "410600",
                "n": "鹤壁市",
                "a": [

                ]
            },
            {
                "c": "410700",
                "n": "新乡市",
                "a": [

                ]
            },
            {
                "c": "410800",
                "n": "焦作市",
                "a": [

                ]
            },
            {
                "c": "410881",
                "n": "济源市",
                "a": [

                ]
            },
            {
                "c": "410900",
                "n": "濮阳市",
                "a": [

                ]
            },
            {
                "c": "411000",
                "n": "许昌市",
                "a": [

                ]
            },
            {
                "c": "411100",
                "n": "漯河市",
                "a": [

                ]
            },
            {
                "c": "411200",
                "n": "三门峡市",
                "a": [

                ]
            },
            {
                "c": "411300",
                "n": "南阳市",
                "a": [

                ]
            },
            {
                "c": "411400",
                "n": "商丘市",
                "a": [

                ]
            },
            {
                "c": "411500",
                "n": "信阳市",
                "a": [

                ]
            },
            {
                "c": "411600",
                "n": "周口市",
                "a": [

                ]
            },
            {
                "c": "411700",
                "n": "驻马店市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "420000",
        "n": "湖北省",
        "a": [
            {
                "c": "420100",
                "n": "武汉市",
                "a": [

                ]
            },
            {
                "c": "420200",
                "n": "黄石市",
                "a": [

                ]
            },
            {
                "c": "420300",
                "n": "十堰市",
                "a": [

                ]
            },
            {
                "c": "420500",
                "n": "宜昌市",
                "a": [

                ]
            },
            {
                "c": "420600",
                "n": "襄阳市",
                "a": [

                ]
            },
            {
                "c": "420700",
                "n": "鄂州市",
                "a": [

                ]
            },
            {
                "c": "420800",
                "n": "荆门市",
                "a": [

                ]
            },
            {
                "c": "420900",
                "n": "孝感市",
                "a": [

                ]
            },
            {
                "c": "421000",
                "n": "荆州市",
                "a": [

                ]
            },
            {
                "c": "421100",
                "n": "黄冈市",
                "a": [

                ]
            },
            {
                "c": "421200",
                "n": "咸宁市",
                "a": [

                ]
            },
            {
                "c": "421300",
                "n": "随州市",
                "a": [

                ]
            },
            {
                "c": "422800",
                "n": "恩施土家族苗族自治州",
                "a": [

                ]
            },
            {
                "c": "429004",
                "n": "仙桃市",
                "a": [

                ]
            },
            {
                "c": "429005",
                "n": "潜江市",
                "a": [

                ]
            },
            {
                "c": "429006",
                "n": "天门市",
                "a": [

                ]
            },
            {
                "c": "429021",
                "n": "神农架林区",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "430000",
        "n": "湖南省",
        "a": [
            {
                "c": "430100",
                "n": "长沙市",
                "a": [

                ]
            },
            {
                "c": "430200",
                "n": "株洲市",
                "a": [

                ]
            },
            {
                "c": "430300",
                "n": "湘潭市",
                "a": [

                ]
            },
            {
                "c": "430400",
                "n": "衡阳市",
                "a": [

                ]
            },
            {
                "c": "430500",
                "n": "邵阳市",
                "a": [

                ]
            },
            {
                "c": "430600",
                "n": "岳阳市",
                "a": [

                ]
            },
            {
                "c": "430700",
                "n": "常德市",
                "a": [

                ]
            },
            {
                "c": "430800",
                "n": "张家界市",
                "a": [

                ]
            },
            {
                "c": "430900",
                "n": "益阳市",
                "a": [

                ]
            },
            {
                "c": "431000",
                "n": "郴州市",
                "a": [

                ]
            },
            {
                "c": "431100",
                "n": "永州市",
                "a": [

                ]
            },
            {
                "c": "431200",
                "n": "怀化市",
                "a": [

                ]
            },
            {
                "c": "431300",
                "n": "娄底市",
                "a": [

                ]
            },
            {
                "c": "433100",
                "n": "湘西土家族苗族自治州",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "440000",
        "n": "广东省",
        "a": [
            {
                "c": "440100",
                "n": "广州市",
                "a": [

                ]
            },
            {
                "c": "440200",
                "n": "韶关市",
                "a": [

                ]
            },
            {
                "c": "440300",
                "n": "深圳市",
                "a": [

                ]
            },
            {
                "c": "440400",
                "n": "珠海市",
                "a": [

                ]
            },
            {
                "c": "440500",
                "n": "汕头市",
                "a": [

                ]
            },
            {
                "c": "440600",
                "n": "佛山市",
                "a": [

                ]
            },
            {
                "c": "440700",
                "n": "江门市",
                "a": [

                ]
            },
            {
                "c": "440800",
                "n": "湛江市",
                "a": [

                ]
            },
            {
                "c": "440900",
                "n": "茂名市",
                "a": [

                ]
            },
            {
                "c": "441200",
                "n": "肇庆市",
                "a": [

                ]
            },
            {
                "c": "441300",
                "n": "惠州市",
                "a": [

                ]
            },
            {
                "c": "441400",
                "n": "梅州市",
                "a": [

                ]
            },
            {
                "c": "441500",
                "n": "汕尾市",
                "a": [

                ]
            },
            {
                "c": "441600",
                "n": "河源市",
                "a": [

                ]
            },
            {
                "c": "441700",
                "n": "阳江市",
                "a": [

                ]
            },
            {
                "c": "441800",
                "n": "清远市",
                "a": [

                ]
            },
            {
                "c": "441900",
                "n": "东莞市",
                "a": [

                ]
            },
            {
                "c": "442000",
                "n": "中山市",
                "a": [

                ]
            },
            {
                "c": "445100",
                "n": "潮州市",
                "a": [

                ]
            },
            {
                "c": "445200",
                "n": "揭阳市",
                "a": [

                ]
            },
            {
                "c": "445300",
                "n": "云浮市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "450000",
        "n": "广西壮族自治区",
        "a": [
            {
                "c": "450100",
                "n": "南宁市",
                "a": [

                ]
            },
            {
                "c": "450200",
                "n": "柳州市",
                "a": [

                ]
            },
            {
                "c": "450300",
                "n": "桂林市",
                "a": [

                ]
            },
            {
                "c": "450400",
                "n": "梧州市",
                "a": [

                ]
            },
            {
                "c": "450500",
                "n": "北海市",
                "a": [

                ]
            },
            {
                "c": "450600",
                "n": "防城港市",
                "a": [

                ]
            },
            {
                "c": "450700",
                "n": "钦州市",
                "a": [

                ]
            },
            {
                "c": "450800",
                "n": "贵港市",
                "a": [

                ]
            },
            {
                "c": "450900",
                "n": "玉林市",
                "a": [

                ]
            },
            {
                "c": "451000",
                "n": "百色市",
                "a": [

                ]
            },
            {
                "c": "451100",
                "n": "贺州市",
                "a": [

                ]
            },
            {
                "c": "451200",
                "n": "河池市",
                "a": [

                ]
            },
            {
                "c": "451300",
                "n": "来宾市",
                "a": [

                ]
            },
            {
                "c": "451400",
                "n": "崇左市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "460000",
        "n": "海南省",
        "a": [
            {
                "c": "460100",
                "n": "海口市",
                "a": [

                ]
            },
            {
                "c": "460200",
                "n": "三亚市",
                "a": [

                ]
            },
            {
                "c": "460321",
                "n": "三沙市",
                "a": [

                ]
            },
            {
                "c": "469001",
                "n": "五指山市",
                "a": [

                ]
            },
            {
                "c": "469002",
                "n": "琼海市",
                "a": [

                ]
            },
            {
                "c": "469003",
                "n": "儋州市",
                "a": [

                ]
            },
            {
                "c": "469005",
                "n": "文昌市",
                "a": [

                ]
            },
            {
                "c": "469006",
                "n": "万宁市",
                "a": [

                ]
            },
            {
                "c": "469007",
                "n": "东方市",
                "a": [

                ]
            },
            {
                "c": "469025",
                "n": "定安县",
                "a": [

                ]
            },
            {
                "c": "469026",
                "n": "屯昌县",
                "a": [

                ]
            },
            {
                "c": "469027",
                "n": "澄迈县",
                "a": [

                ]
            },
            {
                "c": "469028",
                "n": "临高县",
                "a": [

                ]
            },
            {
                "c": "469030",
                "n": "白沙黎族自治县",
                "a": [

                ]
            },
            {
                "c": "469031",
                "n": "昌江黎族自治县",
                "a": [

                ]
            },
            {
                "c": "469033",
                "n": "乐东黎族自治县",
                "a": [

                ]
            },
            {
                "c": "469034",
                "n": "陵水黎族自治县",
                "a": [

                ]
            },
            {
                "c": "469035",
                "n": "保亭黎族苗族自治县",
                "a": [

                ]
            },
            {
                "c": "469036",
                "n": "琼中黎族苗族自治县",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "500000",
        "n": "重庆",
        "a": [
            {
                "c": "500100",
                "n": "重庆市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "510000",
        "n": "四川省",
        "a": [
            {
                "c": "510100",
                "n": "成都市",
                "a": [

                ]
            },
            {
                "c": "510300",
                "n": "自贡市",
                "a": [

                ]
            },
            {
                "c": "510400",
                "n": "攀枝花市",
                "a": [

                ]
            },
            {
                "c": "510500",
                "n": "泸州市",
                "a": [

                ]
            },
            {
                "c": "510600",
                "n": "德阳市",
                "a": [

                ]
            },
            {
                "c": "510700",
                "n": "绵阳市",
                "a": [

                ]
            },
            {
                "c": "510800",
                "n": "广元市",
                "a": [

                ]
            },
            {
                "c": "510900",
                "n": "遂宁市",
                "a": [

                ]
            },
            {
                "c": "511000",
                "n": "内江市",
                "a": [

                ]
            },
            {
                "c": "511100",
                "n": "乐山市",
                "a": [

                ]
            },
            {
                "c": "511300",
                "n": "南充市",
                "a": [

                ]
            },
            {
                "c": "511400",
                "n": "眉山市",
                "a": [

                ]
            },
            {
                "c": "511500",
                "n": "宜宾市",
                "a": [

                ]
            },
            {
                "c": "511600",
                "n": "广安市",
                "a": [

                ]
            },
            {
                "c": "511700",
                "n": "达州市",
                "a": [

                ]
            },
            {
                "c": "511800",
                "n": "雅安市",
                "a": [

                ]
            },
            {
                "c": "511900",
                "n": "巴中市",
                "a": [

                ]
            },
            {
                "c": "512000",
                "n": "资阳市",
                "a": [

                ]
            },
            {
                "c": "513200",
                "n": "阿坝藏族羌族自治州",
                "a": [

                ]
            },
            {
                "c": "513300",
                "n": "甘孜藏族自治州",
                "a": [

                ]
            },
            {
                "c": "513400",
                "n": "凉山彝族自治州",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "520000",
        "n": "贵州省",
        "a": [
            {
                "c": "520100",
                "n": "贵阳市",
                "a": [

                ]
            },
            {
                "c": "520200",
                "n": "六盘水市",
                "a": [

                ]
            },
            {
                "c": "520300",
                "n": "遵义市",
                "a": [

                ]
            },
            {
                "c": "520400",
                "n": "安顺市",
                "a": [

                ]
            },
            {
                "c": "522200",
                "n": "铜仁市",
                "a": [

                ]
            },
            {
                "c": "522300",
                "n": "黔西南布依族苗族自治州",
                "a": [

                ]
            },
            {
                "c": "522400",
                "n": "毕节市",
                "a": [

                ]
            },
            {
                "c": "522600",
                "n": "黔东南苗族侗族自治州",
                "a": [

                ]
            },
            {
                "c": "522700",
                "n": "黔南布依族苗族自治州",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "530000",
        "n": "云南省",
        "a": [
            {
                "c": "530100",
                "n": "昆明市",
                "a": [

                ]
            },
            {
                "c": "530300",
                "n": "曲靖市",
                "a": [

                ]
            },
            {
                "c": "530400",
                "n": "玉溪市",
                "a": [

                ]
            },
            {
                "c": "530500",
                "n": "保山市",
                "a": [

                ]
            },
            {
                "c": "530600",
                "n": "昭通市",
                "a": [

                ]
            },
            {
                "c": "530700",
                "n": "丽江市",
                "a": [

                ]
            },
            {
                "c": "530800",
                "n": "普洱市",
                "a": [

                ]
            },
            {
                "c": "530900",
                "n": "临沧市",
                "a": [

                ]
            },
            {
                "c": "532300",
                "n": "楚雄彝族自治州",
                "a": [

                ]
            },
            {
                "c": "532500",
                "n": "红河哈尼族彝族自治州",
                "a": [

                ]
            },
            {
                "c": "532600",
                "n": "文山壮族苗族自治州",
                "a": [

                ]
            },
            {
                "c": "532800",
                "n": "西双版纳傣族自治州",
                "a": [

                ]
            },
            {
                "c": "532900",
                "n": "大理白族自治州",
                "a": [

                ]
            },
            {
                "c": "533100",
                "n": "德宏傣族景颇族自治州",
                "a": [

                ]
            },
            {
                "c": "533300",
                "n": "怒江傈僳族自治州",
                "a": [

                ]
            },
            {
                "c": "533400",
                "n": "迪庆藏族自治州",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "540000",
        "n": "西藏自治区",
        "a": [
            {
                "c": "540100",
                "n": "拉萨市",
                "a": [

                ]
            },
            {
                "c": "542100",
                "n": "昌都地区",
                "a": [

                ]
            },
            {
                "c": "542200",
                "n": "山南地区",
                "a": [

                ]
            },
            {
                "c": "542300",
                "n": "日喀则地区",
                "a": [

                ]
            },
            {
                "c": "542400",
                "n": "那曲地区",
                "a": [

                ]
            },
            {
                "c": "542500",
                "n": "阿里地区",
                "a": [

                ]
            },
            {
                "c": "542600",
                "n": "林芝地区",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "610000",
        "n": "陕西省",
        "a": [
            {
                "c": "610100",
                "n": "西安市",
                "a": [

                ]
            },
            {
                "c": "610200",
                "n": "铜川市",
                "a": [

                ]
            },
            {
                "c": "610300",
                "n": "宝鸡市",
                "a": [

                ]
            },
            {
                "c": "610400",
                "n": "咸阳市",
                "a": [

                ]
            },
            {
                "c": "610500",
                "n": "渭南市",
                "a": [

                ]
            },
            {
                "c": "610600",
                "n": "延安市",
                "a": [

                ]
            },
            {
                "c": "610700",
                "n": "汉中市",
                "a": [

                ]
            },
            {
                "c": "610800",
                "n": "榆林市",
                "a": [

                ]
            },
            {
                "c": "610900",
                "n": "安康市",
                "a": [

                ]
            },
            {
                "c": "611000",
                "n": "商洛市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "620000",
        "n": "甘肃省",
        "a": [
            {
                "c": "620100",
                "n": "兰州市",
                "a": [

                ]
            },
            {
                "c": "620200",
                "n": "嘉峪关市",
                "a": [

                ]
            },
            {
                "c": "620300",
                "n": "金昌市",
                "a": [

                ]
            },
            {
                "c": "620400",
                "n": "白银市",
                "a": [

                ]
            },
            {
                "c": "620500",
                "n": "天水市",
                "a": [

                ]
            },
            {
                "c": "620600",
                "n": "武威市",
                "a": [

                ]
            },
            {
                "c": "620700",
                "n": "张掖市",
                "a": [

                ]
            },
            {
                "c": "620800",
                "n": "平凉市",
                "a": [

                ]
            },
            {
                "c": "620900",
                "n": "酒泉市",
                "a": [

                ]
            },
            {
                "c": "621000",
                "n": "庆阳市",
                "a": [

                ]
            },
            {
                "c": "621100",
                "n": "定西市",
                "a": [

                ]
            },
            {
                "c": "621200",
                "n": "陇南市",
                "a": [

                ]
            },
            {
                "c": "622900",
                "n": "临夏回族自治州",
                "a": [

                ]
            },
            {
                "c": "623000",
                "n": "甘南藏族自治州",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "630000",
        "n": "青海省",
        "a": [
            {
                "c": "630100",
                "n": "西宁市",
                "a": [

                ]
            },
            {
                "c": "632100",
                "n": "海东市",
                "a": [

                ]
            },
            {
                "c": "632200",
                "n": "海北藏族自治州",
                "a": [

                ]
            },
            {
                "c": "632300",
                "n": "黄南藏族自治州",
                "a": [

                ]
            },
            {
                "c": "632500",
                "n": "海南藏族自治州",
                "a": [

                ]
            },
            {
                "c": "632600",
                "n": "果洛藏族自治州",
                "a": [

                ]
            },
            {
                "c": "632700",
                "n": "玉树藏族自治州",
                "a": [

                ]
            },
            {
                "c": "632800",
                "n": "海西蒙古族藏族自治州",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "640000",
        "n": "宁夏回族自治区",
        "a": [
            {
                "c": "640100",
                "n": "银川市",
                "a": [

                ]
            },
            {
                "c": "640200",
                "n": "石嘴山市",
                "a": [

                ]
            },
            {
                "c": "640300",
                "n": "吴忠市",
                "a": [

                ]
            },
            {
                "c": "640400",
                "n": "固原市",
                "a": [

                ]
            },
            {
                "c": "640500",
                "n": "中卫市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "650000",
        "n": "新疆维吾尔自治区",
        "a": [
            {
                "c": "650100",
                "n": "乌鲁木齐市",
                "a": [

                ]
            },
            {
                "c": "650200",
                "n": "克拉玛依市",
                "a": [

                ]
            },
            {
                "c": "652100",
                "n": "吐鲁番地区",
                "a": [

                ]
            },
            {
                "c": "652200",
                "n": "哈密地区",
                "a": [

                ]
            },
            {
                "c": "652300",
                "n": "昌吉回族自治州",
                "a": [

                ]
            },
            {
                "c": "652700",
                "n": "博尔塔拉蒙古自治州",
                "a": [

                ]
            },
            {
                "c": "652800",
                "n": "巴音郭楞蒙古自治州",
                "a": [

                ]
            },
            {
                "c": "652900",
                "n": "阿克苏地区",
                "a": [

                ]
            },
            {
                "c": "653000",
                "n": "克孜勒苏柯尔克孜自治州",
                "a": [

                ]
            },
            {
                "c": "653100",
                "n": "喀什地区",
                "a": [

                ]
            },
            {
                "c": "653200",
                "n": "和田地区",
                "a": [

                ]
            },
            {
                "c": "654000",
                "n": "伊犁哈萨克自治州",
                "a": [

                ]
            },
            {
                "c": "654200",
                "n": "塔城地区",
                "a": [

                ]
            },
            {
                "c": "654300",
                "n": "阿勒泰地区",
                "a": [

                ]
            },
            {
                "c": "659001",
                "n": "石河子市",
                "a": [

                ]
            },
            {
                "c": "659002",
                "n": "阿拉尔市",
                "a": [

                ]
            },
            {
                "c": "659003",
                "n": "图木舒克市",
                "a": [

                ]
            },
            {
                "c": "659004",
                "n": "五家渠市",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "810000",
        "n": "香港特别行政区",
        "a": [
            {
                "c": "810100",
                "n": "香港岛",
                "a": [

                ]
            },
            {
                "c": "810200",
                "n": "九龙",
                "a": [

                ]
            },
            {
                "c": "810300",
                "n": "新界",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "820000",
        "n": "澳门特别行政区",
        "a": [
            {
                "c": "820100",
                "n": "澳门半岛",
                "a": [

                ]
            },
            {
                "c": "820300",
                "n": "路环岛",
                "a": [

                ]
            },
            {
                "c": "820400",
                "n": "凼仔岛",
                "a": [

                ]
            }
        ]
    },
    {
        "c": "830000",
        "n": "台湾省",
        "a": [
            {
                "c": "830100",
                "n": "台北市",
                "a": [

                ]
            },
            {
                "c": "830200",
                "n": "高雄市",
                "a": [

                ]
            },
            {
                "c": "830300",
                "n": "台南市",
                "a": [

                ]
            },
            {
                "c": "830400",
                "n": "台中市",
                "a": [

                ]
            },
            {
                "c": "830500",
                "n": "南投县",
                "a": [

                ]
            },
            {
                "c": "830600",
                "n": "基隆市",
                "a": [

                ]
            },
            {
                "c": "830700",
                "n": "新竹市",
                "a": [

                ]
            },
            {
                "c": "830800",
                "n": "嘉义市",
                "a": [

                ]
            },
            {
                "c": "830900",
                "n": "宜兰县",
                "a": [

                ]
            },
            {
                "c": "831000",
                "n": "新竹县",
                "a": [

                ]
            },
            {
                "c": "831100",
                "n": "桃园县",
                "a": [

                ]
            },
            {
                "c": "831200",
                "n": "苗栗县",
                "a": [

                ]
            },
            {
                "c": "831300",
                "n": "彰化县",
                "a": [

                ]
            },
            {
                "c": "831400",
                "n": "嘉义县",
                "a": [

                ]
            },
            {
                "c": "831500",
                "n": "云林县",
                "a": [

                ]
            },
            {
                "c": "831600",
                "n": "屏东县",
                "a": [

                ]
            },
            {
                "c": "831700",
                "n": "台东县",
                "a": [

                ]
            },
            {
                "c": "831800",
                "n": "花莲县",
                "a": [

                ]
            },
            {
                "c": "831900",
                "n": "澎湖县",
                "a": [

                ]
            },
            {
                "c": "832000",
                "n": "新北市",
                "a": [

                ]
            },
            {
                "c": "832100",
                "n": "台中县",
                "a": [

                ]
            },
            {
                "c": "832200",
                "n": "连江县",
                "a": [

                ]
            }
        ]
    }
]
export { regionData }