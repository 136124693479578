<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="query.id">{{ l("生成港澳台实名认证地址") }}</span>
        <span v-if="!query.id">{{ l("Create") }}</span>
      </div>
    </div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="姓名" has-feedback>
            <a-input
              :disabled="query.isRealNamed"
              style="width: 100%"
              placeholder="请输入姓名"
              v-decorator="[
                'realName',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="手机号码" has-feedback>
            <a-input
              :disabled="query.isRealNamed"
              style="width: 100%"
              placeholder="请输入手机号码"
              v-decorator="[
                'phoneNumber',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="身份证号码" has-feedback>
            <a-input
              :disabled="query.isRealNamed"
              placeholder="请输入身份证号码"
              v-decorator="[
                'idCard',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="实名认证类型" has-feedback>
            <a-select
              :disabled="query.isRealNamed"
              v-decorator="[
                'verifiedWay',
                {
                  rules: [
                    {
                      required: true,
                      message: l('ThisFieldIsRequired'),
                    },
                  ],
                  initialValue: undefined,
                },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in defaultSelectList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <!-- <a-form-item label="证件类型" required>

            <a-radio-group v-decorator="['CertType']" default-value="B">
              <a-radio value="B"> 港澳居民来往内地通行证号 </a-radio>
              <a-radio value="C"> 台湾居民来往大陆通行证号 </a-radio>
            </a-radio-group>
          </a-form-item> -->
          <a-form-item label="证件类型" has-feedback>
            <a-select
              :disabled="query.isRealNamed"
              v-decorator="[
                'certType',
                {
                  rules: [
                    {
                      required: true,
                      message: l('ThisFieldIsRequired'),
                    },
                  ],
                  initialValue: undefined,
                },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in certTypeSelectList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('通行证照(人像图片)')">
            <a-upload
              :action="uploadUrl"
              :multiple="false"
              :data="{ subdir: this.subdirName }"
              list-type="picture"
              :file-list="fileList"
              :showUploadList="{
                showPreviewIcon: true,
                showRemoveIcon: !isDisabled,
              }"
              @change="handleUploadChange"
            >
              <a-button v-if="!isDisabled">
                <a-icon type="upload" /> 上传附件
              </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item class="btn--container" v-if="!query.isRealNamed">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit">
          {{ l("获取实名认证二维码") }}
        </a-button>
                <!-- <a-button type="button" @click="getUrlCode('c6ac1c03-7312-4cb6-9e11-baf459cecc0b')">
          {{ l("测试") }}
        </a-button> -->
      </a-form-item>
    </a-form>
    <a-modal
      centered
      :title="inputModalParams.title"
      :visible="inputModalParams.visible"
      :confirm-loading="inputModalParams.confirmLoading"
      :maskClosable="inputModalParams.maskClosable"
      :destroyOnClose="inputModalParams.destroyOnClose"
      :width="inputModalParams.width"
      :footer="null"
      @ok="inputModalParams.confirm"
      @cancel="inputModalParams.cancel"
    >
      <div style="text-align: center; position: relative">
        <a-spin spinning="true" tip="加载中..." style="z-index: 1">
          <div style="height: 380px; width: 380px"></div>
        </a-spin>
        <div style="position: absolute; z-index: 2; top: 0; left: 40px">
          <img :src="inputModalParams.url" />
        </div>
        <!-- <div style="margin-top: 10px">
          项目名称：{{ inputModalParams.desc.name }}
        </div>
        <div>项目编码：{{ inputModalParams.desc.code }}</div> -->
      </div>
    </a-modal>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { PersonnelServiceProxy } from "../services/personnel-proxies";
import moment from "moment";
import { AppConsts } from "@/abpPro/AppConsts";
export default {
  mixins: [ModalComponentBase],
  name: "personnel-surename",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      _serviceProxy: "",
      labelCol: {
        span: 5,
      },
      defaultSelectList: [
        { label: "纯三要素", value: "4" },
        { label: "纯四要素", value: "5" },
        { label: "人脸识别", value: "9" },
      ],
      certTypeSelectList: [
        { label: "港澳居民来往内地通行证号", value: "B" },
        { label: "台湾居民来往大陆通行证号", value: "C" },
      ],
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      isDisabled: false,
      inputModalParams: {
        destroyOnClose: true,
        maskClosable: false,
        confirmLoading: false,
        visible: false,
        width: 500,
        title: "项目二维码",
        url: "",
        desc: {
          nane: "",
          code: "",
        },
        cancelText: "取消",
        okText: "确认",
        form: {},
        confirm: () => {},
        cancel: () => {
          this.inputModalParams.confirm = () => {};
          this.inputModalParams.visible = false;
        },
      },
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new PersonnelServiceProxy(this.$apiUrl, this.$api);
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
  },
  async mounted() {
    console.log(this.query);
    if (this.query.id) {
      await this.getData();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      var res = await this._serviceProxy.getEditById(this.query.id);
      this.spinning = false;
      //   console.log(res);
      this.$nextTick(() => {
        this.form.setFieldsValue({
          //   ...res,
          realName: res.realName,
          idCard: res.idCard,
          phoneNumber: res.phoneNumber,
          verifiedWay: this.returnVal(res.verifiedWay),
          certType: res.certType=="0"?"B":res.certType,
          //   id: this.id,
        });
      });
      res.images = res.idCardPhotoFront;
      if (res.images && res.images.indexOf(",") !== -1) {
        let arr = res.images.split(",");
        arr.map((item, i) => {
          let n = item.split("/");
          this.fileList.push({
            uid: `-${i + 1}`,
            url: `${AppConsts.uploadBaseUrl}${item}`,
            name: n[n.length - 1],
            response: { data: { Path: item } },
            status: "done",
          });
        });
      } else if (res.images && res.images !== "undefined") {
        let n = res.images.split("/");
        this.fileList.push({
          uid: `-1`,
          url: `${AppConsts.uploadBaseUrl}${res.images}`,
          name: n[n.length - 1],
          response: { data: { Path: res.images } },
          status: "done",
        });
      }
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      if (this.fileList.length == 0) {
        this.$message.error("请上传通行证照(人像图片)!");
        return false;
      }
      let fileUrlList = [];
      this.fileList.map((item) => {
        fileUrlList.push(item.response.data.Path);
      });
      console.log(fileUrlList);
      let images =
        fileUrlList.length > 1 ? fileUrlList.join(",") : `${fileUrlList[0]}`;
      console.log(images);
      this.form.validateFieldsAndScroll(async (err, values) => {
        console.log(err);
        console.log(values);

        if (!err) {
          try {
            this.spinning = true;
            let res = await this._serviceProxy.GetPersonnelSureNameAuthUrl({
              ...values,
              id: this.query.id,
              type: "FlexibleEmployment",
              status: 1,
              verifiedWay: Number(values.verifiedWay),
              sureNameType: 4,
              idCardPhotoFront: images,
            });
            this.spinning = false;
            console.log(res);
            if (res.status == 200) {
              this.$notification["success"]({
                message: this.l("SavedSuccessfully"),
              });
              // this.success(true);
              // debugger;
              this.getUrlCode(res.data);
            }
          } catch {
            this.spinning = false;
          }
        }
      });
    },
    returnVal(type) {
      switch (type) {
        case "ThreeElements":
          return "4";
        case "FourElements":
          return "5";
        case "FaceRecognition":
          return "9";
      }
    },
    // 上传组件 @change
    handleUploadChange(info) {
      console.log(info);
      if (!this.query.id) {
        let isUpload = this.beforeUpload(info.file);
        if (!isUpload) {
          return false;
        }
      }
      let fileList = [...info.fileList];
      if (fileList.length > 1) {
        this.$message.error("只能上传一张照片!");
        return false;
      }
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
      this.fileList = fileList;
      // console.log(this.fileList);
    },
    // 上传前校验
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("只能上传jpg/png格式的照片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片不得大于2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    getUrlCode(id) {
      var _this = this;
      _this.inputModalParams.title = "港澳台实名认证二维码";
      _this.inputModalParams.desc.name = "";
      _this.inputModalParams.desc.code = "";
        _this.inputModalParams.url = `${this.$apiUrl}/api/services/app/Personnel/GetSureNameQrCode?id=${id}`;
      _this.inputModalParams.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container {
  display: block;
  margin: 10px auto;
  text-align: center;
}
</style>