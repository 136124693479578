<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>人员技能</span>
      </div>
    </div>
      <!-- 按钮 -->
      <!-- <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="9">
          <a-input
            v-model="filterText"
            :placeholder="l('SearchWithThreeDot')"
          />
        </a-col>
        <a-col class="gutter-row" :span="9">
          <a-button type="primary" @click="getData">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" :span="6" style="float: right">
          <a-button
            type="primary"
            @click="createOrEdit(null)"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
        </a-col>
      </a-row> -->

      <!-- <div class="my-md">
        <a-alert :type="'info'" :showIcon="true" style="margin: 10px 0;">
          <template slot="message">
            <span v-html="l('GridSelectedXItemsTips', selectedRowKeys.length)"></span>
            <a @click="restCheckStatus()" class="ml-md">{{ l("ClearEmpty") }}</a>
            <a-divider type="vertical"></a-divider>
            <a @click="refreshGoFirstPage()">{{ l("Refresh") }}</a>
          </template>
        </a-alert>
      </div> -->
    <a-modal v-model="visible" title="技能图片" @ok="handleOk" @cancel = "handleCancel" >
     <!-- <img :src="imageUrl"/> -->
        <!-- <a-form-item label="技能图片"> -->
          <div style="margin-left: 14px;">
          <a-upload
            :action="uploadUrl"
            :multiple="true"
            :data="{ subdir: this.subdirName }"
            :file-list="fileList"
            :showUploadList="{
              showPreviewIcon: true,
              showRemoveIcon: !isDisabled,
            }"
            list-type="picture-card"
          >
            <a-button :disabled="isDisabled" v-if="!isDisabled">
              <a-icon type="upload" /> 垫付证明
            </a-button>
          </a-upload>
          </div>
        <!-- </a-form-item> -->
    </a-modal>

    <ele-table
      :columns="columns"
      :table-data="tableData"
      :actions-type="actionsType"
      :is-full="false"
      :hide-row-selection="true"
      :hide-pagination="true"
    >

    </ele-table>
      <a-table
        v-if="false"
        class="list-table"
        @change="sortData"
        :pagination="false"
        :row-selection="null"
        :rowKey="(tableDatas) => tableDatas.id"
        :columns="columns"
        :data-source="tableData"
        selectedRowKeys
      >
        <span slot="isDefault" slot-scope="isDefault">
          <a-tag :color="isDefault=='是'?'geekblue':''">
            {{ isDefault.toUpperCase() }}
          </a-tag>
        </span>
        <span slot="actions" slot-scope="text, record">
          <a class="table-edit" @click="showModal(record)">
            <a-icon type="profile" /> 查看技能图片
          </a>        
        </span>
      </a-table>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import { AppConsts } from "@/abpPro/AppConsts";
import { PersonnelServiceProxy } from "../services/personnel-proxies";
import EleTable from "@/components/ele-table";
import { environment } from "@/environments/environment";

export default {
  mixins: [ModalComponentBase],
  name: "bankInfoList",
  components: {EleTable},
  data() {
    return {
      spinning: false,
      visible: false,
       imageUrl:"",
      // 搜索
      filterText: "",
      // 选择多少项
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      _serviceProxy: "",
      commonService: null,
      actionsType: {},
      columns: [
        {
          title: "技能类型",
          dataIndex: "type",
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "工作年限",
          dataIndex: "years",
          align: "center",
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
            // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      isDisabled: true,
    };
  },
  created() {
    this._serviceProxy = new PersonnelServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType()
        this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  methods: {
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("查看技能图片"),
              icon: "profile",
              showFunc(record) {
                return record.isDefault == "否"
              },
              fn: (data) => {
                _this.showModal(data);
              },
            },
          ],
          // delete: { granted: this.isGranted('Pages.Customer.Delete'), name: this.l("Delete"), fn: (data) => {
          //   _this.deleteItem(data.id)
          // } },
        },
      };
      this.actionsType = obj;
    },
    showModal(e) {
      this.visible = true;
      // this.imageUrl= AppConsts.uploadBaseUrl+e.images;
                this.fileList = [];
                if (e.images && e.images.indexOf(",") !== -1) {
                  let arr = e.images.split(",");
                  arr.map((item, i) => {
                    let n = item.split("/");
                    this.fileList.push({
                      uid: `-${i + 1}`,
                      url: `${AppConsts.uploadBaseUrl}${item}`,
                      name: n[n.length - 1],
                      response: { data: { Path: item } },
                      status: "done",
                    });
                  });
                } else if (
                  e.images &&
                  e.images !== "undefined"
                ) {
                  let n = e.images.split("/");
                  this.fileList.push({
                    uid: `-1`,
                    url: `${AppConsts.uploadBaseUrl}${e.images}`,
                    name: n[n.length - 1],
                    response: { data: { Path: e.images } },
                    status: "done",
                  });
                }
    },
    handleOk(e) {
      this.fileList = [];
      this.visible = false;
    },
    handleCancel(e) {
            this.fileList = [];
      this.visible = false;
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      let id = this._props.modelData.id;
      var response = await this._serviceProxy.getPagedForPersonnelSkills(id, this.filterText);
      this.spinning = false;
      response.map((item) => {
        item.isDefault = item.isDefault ? "是" : "否";
        item.isDefaultColor = item.isDefault ? "geekblue" : "";
      });
      console.log(response);
      this.tableData = response;
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },

    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = ''
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getData();
    },
    /**
     * 新建修改image
     */
    createOrEdit(item) {
       let image = item.images;
      ModalHelper.create(
        ViewImage,
        {
          image:"image",
        },
        {
          width: "600px",
          isChange: true,
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    sortData() {},
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    setDefault(item) {
      this.message.confirm("是否设为默认?", "", (res) => {
        if (res) {
          let id = item.id;
          this.spinning = true;
          this._serviceProxy
            .setBankDefault(id)
            .finally(() => {
              this.spinning = false;
            })
            .then(() => {
              this.refreshGoFirstPage();
              this.$notification["success"]({
                message: "更改成功",
              });
            });
        }
      });
    },
  },
  computed: {
    // rowSelection() {
    //   return {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //       this.selectedRowKeys = selectedRowKeys;
    //       this.selectedRows = selectedRows;
    //       console.log(
    //         `selectedRowKeys: ${selectedRowKeys}`,
    //         "selectedRows: ",
    //         selectedRows
    //       );
    //     },
    //   };
    // },
    initRowSelection() {
      const rowSelection = {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
      };
      return rowSelection;
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}
.list-table {
    border: 1px solid #e8e8e8;
    // margin: 20px;
    // padding: 20px;
    .table-edit {
            margin-left: 10px;
            // margin-right: 10px;
    }
}
</style>
