<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="query.id">{{ l("Edit") }}</span>
        <span v-if="!query.id">{{ l("Create") }}</span>
      </div>
    </div>
    <a-form :form="form" @submit="handleSubmit">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="姓名" has-feedback>
            <a-input
              :disabled="query.isRealNamed"
              style="width: 100%"
              placeholder="请输入姓名"
              v-decorator="[
                'realName',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="手机号码" has-feedback>
            <a-input
              :disabled="query.isRealNamed"
              style="width: 100%"
              placeholder="请输入手机号码"
              v-decorator="[
                'phoneNumber',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="身份证号码" has-feedback>
            <a-input
              :disabled="query.isRealNamed"
              placeholder="请输入身份证号码"
              v-decorator="[
                'idCard',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="实名认证类型" has-feedback>
            <a-select
              :disabled="query.isRealNamed"
              v-decorator="[
                'verifiedWay',
                {
                  rules: [
                      {
                      required: true,
                      message: l('ThisFieldIsRequired'),
                      },
                  ],
                  initialValue: undefined,
                },
              ]"
              placeholder="请选择"
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in defaultSelectList"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item class="btn--container" v-if="!query.isRealNamed">
        <a-button type="button" @click="close()">
          {{ l("Cancel") }}
        </a-button>
        <a-button type="primary" html-type="submit">
          {{ l("Save") }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-spin>
</template>
<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { PersonnelServiceProxy } from "../services/personnel-proxies";
import moment from "moment";
export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-personnel",
  data() {
    return {
      // 获取到的数据
      entity: {},
      spinning: false,
      _serviceProxy: "",
      labelCol: {
        span: 5,
      },
      defaultSelectList: [
        { label: "纯三要素", value: "4" },
        { label: "纯四要素", value: "5" },
        { label: "人脸识别", value: "9" },
        { label: "三要素+人脸", value: "0" },
        { label: "四要素+人脸", value: "2" },
      ],
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new PersonnelServiceProxy(this.$apiUrl, this.$api);
  },
  async mounted() {
    console.log(this.query);
    if (this.query.id) {
      await this.getData();
    }
  },
  methods: {
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      var res = await this._serviceProxy.getEditById(this.query.id);
      this.spinning = false;
    //   console.log(res);
      this.$nextTick(() => {
        this.form.setFieldsValue({
          //   ...res,
          realName: res.realName,
          idCard: res.idCard,
          phoneNumber: res.phoneNumber,
          verifiedWay: this.returnVal(res.verifiedWay),
        //   id: this.id,
        });
      });
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll(async (err, values) => {
        console.log(err);
        console.log(values);

        if (!err) {
          try {
            this.spinning = true;
            let res = await this._serviceProxy.createOrUpdate({
              ...values,
              id: this.query.id,
              type:"FlexibleEmployment",
              status:1,
              verifiedWay: Number(values.verifiedWay),
            });
            this.spinning = false;
            console.log(res);
            if (res.status == 200) {
              this.$notification["success"]({
                message: this.l("SavedSuccessfully"),
              });
              this.success(true);
            }
          } catch {
            this.spinning = false;
          }
        }
      });
    },
    returnVal(type) {
      switch(type) {
        case 'ThreeElements':
          return '4'
        case 'FourElements':
          return '5'
        case 'FaceRecognition':
          return '9'
        case 'ThreeElementsFaceRecognition':
          return '0'
        case 'FourElementsFaceRecognition':
          return '2'
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container {
  display: block;
  margin: 10px auto;
  text-align: center;
}
</style>