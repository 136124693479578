var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[(_vm.query.id)?_c('span',[_vm._v(_vm._s(_vm.l("生成港澳台实名认证地址")))]):_vm._e(),(!_vm.query.id)?_c('span',[_vm._v(_vm._s(_vm.l("Create")))]):_vm._e()])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"姓名","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'realName',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'realName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.query.isRealNamed,"placeholder":"请输入姓名"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"手机号码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phoneNumber',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'phoneNumber',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.query.isRealNamed,"placeholder":"请输入手机号码"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"身份证号码","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'idCard',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'idCard',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.query.isRealNamed,"placeholder":"请输入身份证号码"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"实名认证类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'verifiedWay',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('ThisFieldIsRequired'),
                  } ],
                initialValue: undefined,
              } ]),expression:"[\n              'verifiedWay',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('ThisFieldIsRequired'),\n                  },\n                ],\n                initialValue: undefined,\n              },\n            ]"}],attrs:{"disabled":_vm.query.isRealNamed,"placeholder":"请选择"}},_vm._l((_vm.defaultSelectList),function(it,i){return _c('a-select-option',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"证件类型","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'certType',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('ThisFieldIsRequired'),
                  } ],
                initialValue: undefined,
              } ]),expression:"[\n              'certType',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('ThisFieldIsRequired'),\n                  },\n                ],\n                initialValue: undefined,\n              },\n            ]"}],attrs:{"disabled":_vm.query.isRealNamed,"placeholder":"请选择"}},_vm._l((_vm.certTypeSelectList),function(it,i){return _c('a-select-option',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('通行证照(人像图片)')}},[_c('a-upload',{attrs:{"action":_vm.uploadUrl,"multiple":false,"data":{ subdir: this.subdirName },"list-type":"picture","file-list":_vm.fileList,"showUploadList":{
              showPreviewIcon: true,
              showRemoveIcon: !_vm.isDisabled,
            }},on:{"change":_vm.handleUploadChange}},[(!_vm.isDisabled)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传附件 ")],1):_vm._e()],1)],1)],1)],1),(!_vm.query.isRealNamed)?_c('a-form-item',{staticClass:"btn--container"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.l("获取实名认证二维码"))+" ")])],1):_vm._e()],1),_c('a-modal',{attrs:{"centered":"","title":_vm.inputModalParams.title,"visible":_vm.inputModalParams.visible,"confirm-loading":_vm.inputModalParams.confirmLoading,"maskClosable":_vm.inputModalParams.maskClosable,"destroyOnClose":_vm.inputModalParams.destroyOnClose,"width":_vm.inputModalParams.width,"footer":null},on:{"ok":_vm.inputModalParams.confirm,"cancel":_vm.inputModalParams.cancel}},[_c('div',{staticStyle:{"text-align":"center","position":"relative"}},[_c('a-spin',{staticStyle:{"z-index":"1"},attrs:{"spinning":"true","tip":"加载中..."}},[_c('div',{staticStyle:{"height":"380px","width":"380px"}})]),_c('div',{staticStyle:{"position":"absolute","z-index":"2","top":"0","left":"40px"}},[_c('img',{attrs:{"src":_vm.inputModalParams.url}})])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }